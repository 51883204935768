<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Робота із під'їздами та поверхами
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12">
                <AddressElementSelect v-model="building"
                                      label="Будинок(-ки)" filled req
                                      select_type="building"
                />
              </v-col>
              <v-col>
                <v-tabs class="custom-tabs" color="success">
                  <v-tab class="text-left justify-start">
                    Робота із під'їздами
                  </v-tab>
                  <v-tab class="text-left justify-start">
                    Робота із поверхами
                  </v-tab>
                  <v-tab-item :disabled="!building">
                    <div class="form-table-custom mt-4" style="min-height: 120px">
                      <div class="table-command-panel mt-3 mb-3">
                        <v-btn plain small class="grey lighten-3 mr-2"
                               :disabled="!building"
                               @click="onEntranceAddRow">
                          <v-icon small color="success" left>mdi-plus</v-icon>
                          Додати
                        </v-btn>
                        <v-btn plain small class="grey lighten-3"
                               :disabled="!selected_entrance.length"
                               @click="onEntranceDelete"
                        >
                          <v-icon small color="error" left>mdi-delete</v-icon>
                          Вилучити
                        </v-btn>
                      </div>
                      <table>
                        <thead>
                        <tr>
                          <th rowspan="2" style="width: 30px;"></th>
                          <th rowspan="2" style="min-width: 70px;">№ п/п</th>
                          <th rowspan="1" colspan="2">
                            Діапазон номерів квартир
                          </th>
                          <th rowspan="2" style="min-width: 70px;">Під’їзд</th>
                        </tr>
                        <tr>
                          <th>
                            з
                          </th>
                          <th>
                            по
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, idx) in entrance_table" :key="`entrance-${idx}`">
                          <td style="width: 30px">
                            <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                        :disabled="!building"
                                        :value="selected_entrance.includes(item)"
                                        @change="onSelectEntrance(item)"/>
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text" v-integer disabled :value="idx + 1">
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-integer v-model.number="item.from"
                                   :class="!item.from ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-integer
                                   v-model.number="item.to"
                                   :class="!item.to ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text" v-integer
                                   v-model.number="item.entrance"
                                   :class="!item.entrance ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                        </tr>
                        <tr v-if="!entrance_table.length">
                          <td colspan="5"
                              class="success--text"
                              style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
                          >
                            Таблиця порожня. Спершу додайте рядки)
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="table-footer-panel">
                        <v-spacer></v-spacer>
                        <v-btn class="grey lighten-3"
                               :disabled="!documentReady()"
                               @click="crud_operation(1)"
                        >
                          <v-icon class="mr-2" color="success">mdi-content-save-check-outline</v-icon>
                          Виконати
                        </v-btn>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item :disabled="!building">
                    <div class="form-table-custom mt-4">
                      <div class="table-command-panel mt-3 mb-3">
                        <v-btn plain small class="grey lighten-3 mr-2" @click="onFloorAddRow" :disabled="!building">
                          <v-icon small color="success" left>mdi-plus</v-icon>
                          Додати
                        </v-btn>
                        <v-btn plain small class="grey lighten-3"
                               :disabled="!selected_floor.length"
                               @click="onFloorDelete"
                        >
                          <v-icon small color="error" left>mdi-delete</v-icon>
                          Вилучити
                        </v-btn>
                      </div>
                      <table>
                        <thead>
                        <tr>
                          <th rowspan="2" style="width: 30px;"></th>
                          <th rowspan="2" style="min-width: 70px;">№ п/п</th>
                          <th rowspan="1" colspan="2">
                            Діапазон номерів квартир
                          </th>
                          <th rowspan="2" style="min-width: 70px;">Під’їзд</th>
                          <th rowspan="2" style="min-width: 70px;">Поверх</th>
                        </tr>
                        <tr>
                          <th>
                            з
                          </th>
                          <th>
                            по
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, idx) in floor_table" :key="`entrance-${idx}`">
                          <td style="width: 30px">
                            <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                        :disabled="!building"
                                        :value="selected_floor.includes(item)"
                                        @change="onSelectFloor(item)"/>
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text" v-integer disabled :value="idx + 1">
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-integer v-model.number="item.from"
                                   :class="!item.from ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-integer
                                   v-model.number="item.to"
                                   :class="!item.to ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text" v-integer
                                   v-model.number="item.entrance"
                                   :class="!item.entrance ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text" v-integer
                                   v-model.number="item.floor"
                                   :class="!item.floor ? 'input-error' : ''"
                                   :disabled="!building"
                            >
                          </td>
                        </tr>
                        <tr v-if="!floor_table.length">
                          <td colspan="6"
                              class="success--text"
                              style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
                          >
                            Таблиця порожня. Спершу додайте рядки)
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="table-footer-panel">
                        <v-spacer></v-spacer>
                        <v-btn class="grey lighten-3"
                               :disabled="!documentReady('floor')"
                               @click="crud_operation(2)"
                        >
                          <v-icon class="mr-2" color="success">mdi-content-save-check-outline</v-icon>
                          Виконати
                        </v-btn>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fillTablesAPI from '@/utils/axios/fill_tables'
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "FillFloorAndEntrance",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      building: null,
      entrance_table: [],
      selected_entrance: [],
      floor_table: [],
      selected_floor: [],
    }
  },
  methods: {
    documentReady(table = 'entrance') {
      if (!this.building) return false
      if (table === 'entrance') {
        return !this.entrance_table.filter(item => !item.from || !item.to || !item.entrance).length
      }
      if (table === 'floor') {
        return !this.floor_table.filter(item => !item.from ||
                                         !item.to || !item.entrance || !item.floor).length
      }
    },
    onEntranceAddRow() {
      const prev = this.entrance_table[this.entrance_table.length - 1]
      const row = {from: 0, to: 0, entrance: 0}
      if (prev && prev.from !== 0 && prev.to !== 0) {
        row.from = prev.to + 1
        row.to = row.from + (prev.to - prev.from)
        row.entrance = prev.entrance + 1
      }
      this.entrance_table.push(row)
    },
    onFloorAddRow() {
      const prev = this.floor_table[this.floor_table.length - 1]
      const row = {from: 0, to: 0, entrance: 0, floor: 0}
      if (prev && prev.from !== 0 && prev.to !== 0) {
        row.from = prev.to + 1
        row.to = row.from + (prev.to - prev.from)
        row.entrance = prev.entrance
        row.floor = prev.floor + 1
      }
      this.floor_table.push(row)
    },
    onSelectEntrance(payload) {
      if (this.selected_entrance.includes(payload)) {
        this.selected_entrance.splice(this.selected_entrance.indexOf(payload), 1)
      } else {
        this.selected_entrance.push(payload)
      }
    },
    onEntranceDelete() {
      if (!this.selected_entrance.length) return
      this.selected_entrance.forEach(item => {
        this.entrance_table.splice(this.entrance_table.indexOf(item), 1)
      })
      this.selected_entrance = []
    },
    onFloorDelete() {
      if (!this.selected_floor.length) return
      this.selected_floor.forEach(item => {
        this.floor_table.splice(this.floor_table.indexOf(item), 1)
      })
      this.selected_floor = []
    },
    onSelectFloor(payload) {
      if (this.selected_floor.includes(payload)) {
        this.selected_floor.splice(this.selected_floor.indexOf(payload), 1)
      } else {
        this.selected_floor.push(payload)
      }
    },
    getOperationDescription(payload) {
      if ("entrance" in payload) {
        return `Підїзди вастановлено у ${payload.entrance} квартирах`
      }
      if ("floor" in payload) {
        return `Поверхи вастановлено у ${payload.floor} квартирах`
      }
    },
    crud_operation(operation) {
      if (this.documentReady()) {

        const payload = {
          building_id: this.building,
          operation: operation === 1 ? 'entrance' : 'floor',
          rows: operation === 1 ? this.entrance_table : this.floor_table
        }

        payload.rows = payload.rows.map(item => {
          return {flat_from: item.from, flat_to: item.to, entrance: item.entrance, floor: item.floor || null}
        })

        fillTablesAPI.fill_entrance_and_floor(payload)
            .then(response => response.data)
            .then(data => {
              this.$store.dispatch(ALERT_SHOW, {text: this.getOperationDescription(data), color: 'success'})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            });

      }
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>